
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';
@Component({})
class SessionChat extends Vue {
  @Prop() private isCheckboxDisable!: boolean;
  @Prop() private moduleName!: string;
  @Prop() private isCheckboxChecked!: boolean;
  private localIsCheckboxChecked = false;
  private showInfoString = false;
  // methods
  private mounted() {
    this.localIsCheckboxChecked = this.isCheckboxChecked;
  }
  private GetCheckboxLabelText() {
    // get label text based on module name
    return this.$t('Program.SessionChat.EnableSessionChat',{item: this.moduleName});
  }
  private SetCheckboxValue() {
    // assign value to local variable
    this.localIsCheckboxChecked = (this.$refs.chkSessionChatVisibility as HTMLInputElement).checked;
    // emit the value to parent
    this.$emit('set-checkbox-value', this.localIsCheckboxChecked);
  }
  private GetTitle(){
    // if checkbox is disable then return string
    if (!this.isCheckboxDisable) {
      return this.$t('Program.SessionChat.SessionChatToolTip');
    } return '';
  }
  // Used to show the information string
  private GetInformationString(val: boolean) {
    this.showInfoString = val;
  }
  // Watchers
  @Watch('isCheckboxChecked')
  private CheckChangeInCheckboxValue(val: boolean) {
    // assign value to local variable
    this.localIsCheckboxChecked = val;
  }
}
// export the component
export default toNative(SessionChat);
