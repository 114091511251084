import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "top-margin-in-fields position-relative" }
const _hoisted_2 = { class: "new-sub-section-heading" }
const _hoisted_3 = ["title"]
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.$t("Program.SessionChat")) + " ", 1),
      _createElementVNode("i", {
        class: "fa fa-info-circle information-icon",
        onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.GetInformationString(true))),
        onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.GetInformationString(false)))
      }, null, 32)
    ]),
    _withDirectives(_createElementVNode("div", { class: "information-icon-div" }, _toDisplayString(_ctx.$t('ChatRequiredAppVersionTooltip')), 513), [
      [_vShow, _ctx.showInfoString]
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("label", {
        class: _normalizeClass([{'color-darkgray' : !_ctx.isCheckboxDisable}, "description"]),
        title: _ctx.GetTitle(),
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.SetCheckboxValue()))
      }, [
        _withDirectives(_createElementVNode("input", {
          ref: "chkSessionChatVisibility",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localIsCheckboxChecked) = $event)),
          disabled: !_ctx.isCheckboxDisable,
          class: "checkbox-alignment",
          type: "checkbox"
        }, null, 8, _hoisted_4), [
          [_vModelCheckbox, _ctx.localIsCheckboxChecked]
        ]),
        _createTextVNode(_toDisplayString(_ctx.GetCheckboxLabelText()), 1)
      ], 10, _hoisted_3)
    ])
  ]))
}