import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "div-validation" }
const _hoisted_2 = { class: "back-navigation-p cursor-pointer" }
const _hoisted_3 = { class: "chat-detail-body" }
const _hoisted_4 = { class: "display-flex width60" }
const _hoisted_5 = { class: "margin-left-10 width100" }
const _hoisted_6 = { class: "footer-tab" }
const _hoisted_7 = { class: "parent-div-buttons" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = { class: "" }
const _hoisted_10 = ["disabled"]
const _hoisted_11 = {
  key: 1,
  class: "event-setting-addicon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValidationSummary = _resolveComponent("ValidationSummary")!
  const _component_ImageProcess = _resolveComponent("ImageProcess")!

  return (_openBlock(), _createElementBlock("div", {
    class: "parent-chat-tab border-radius-20",
    onKeypress: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.isDirtyObject && _ctx.isDirtyObject(...args))),
    onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.isDirtyObject && _ctx.isDirtyObject(...args))),
    onMousemove: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.isDirtyObject && _ctx.isDirtyObject(...args))),
    onTouchstart: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.isDirtyObject && _ctx.isDirtyObject(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isValidationSummaryVisible)
        ? (_openBlock(), _createBlock(_component_ValidationSummary, {
            key: 0,
            "error-fields": _ctx.validationErrorFields,
            onCloseClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.CloseValidationSummary($event)))
          }, null, 8, ["error-fields"]))
        : _createCommentVNode("", true)
    ]),
    (!_ctx.showImageProcess)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "back-navigation-div",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.BackNavigation()))
        }, [
          _cache[9] || (_cache[9] = _createElementVNode("i", {
            class: "fa fa-arrow-left back-navigation-i cursor-pointer",
            "aria-hidden": "true"
          }, null, -1)),
          _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("EventSetting.CustomTheme.BackNavigation")), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", null, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t("EventSetting.Chat.ChannelName")), 1)
          ]),
          _createElementVNode("div", null, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.v$.localChat.Name.$model) = $event)),
              type: "text",
              class: _normalizeClass(["form-control", _ctx.status(_ctx.v$.localChat.Name)]),
              maxlength: 120
            }, null, 2), [
              [_vModelText, _ctx.v$.localChat.Name.$model]
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _cache[10] || (_cache[10] = _createElementVNode("div", { class: "display-flex" }, [
        _createElementVNode("div", { class: "width100" }, [
          _createElementVNode("hr", { class: "footer-hr" })
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", null, [
          _createElementVNode("button", {
            class: "btn btn-secondary btn-size-default btn-cancelevent",
            disabled: !_ctx.isObjectDirty,
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.Discard(_ctx.v$)))
          }, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t("Button.Discard")), 1)
          ], 8, _hoisted_8)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("button", {
            class: "btn btn-primary btn-size-default width100 btn-create-event",
            disabled: !_ctx.isObjectDirty,
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.Submit()))
          }, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t("Button.Save")), 1)
          ], 8, _hoisted_10)
        ])
      ])
    ]),
    (_ctx.showImageProcess)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createVNode(_component_ImageProcess, {
            "image-src": _ctx.GetImageUrl(),
            "image-full-src": _ctx.GetImageUploadUrl(),
            "heading-title": _ctx.$t('ImageProcess.iconImage'),
            onSetImageIconUrl: _ctx.SetImageIconUrl,
            onSelectBack: _ctx.clickOnImage,
            onGetPreviousIconUrl: _ctx.GetPreviousIconUrl
          }, null, 8, ["image-src", "image-full-src", "heading-title", "onSetImageIconUrl", "onSelectBack", "onGetPreviousIconUrl"])
        ]))
      : _createCommentVNode("", true)
  ], 32))
}