import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "chat-tab" }
const _hoisted_2 = {
  key: 0,
  id: "chatTab",
  class: "parent-div-chat background-white border-radius-20"
}
const _hoisted_3 = { class: "chat-heading" }
const _hoisted_4 = { class: "display-flex align-items-end position-relative" }
const _hoisted_5 = { class: "primary-heading no-margin" }
const _hoisted_6 = { class: "div-chat" }
const _hoisted_7 = { class: "div-chat-block border-radius-10" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "spn-create-channel" }
const _hoisted_10 = { class: "div-open-event-chat-button" }
const _hoisted_11 = ["src"]
const _hoisted_12 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueModalPopUp = _resolveComponent("VueModalPopUp")!
  const _component_ChatListing = _resolveComponent("ChatListing")!
  const _component_ChatDetail = _resolveComponent("ChatDetail")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showVueModalPopUp)
      ? (_openBlock(), _createBlock(_component_VueModalPopUp, {
          key: 0,
          "modal-pop-up": _ctx.unsavedModalPopUp,
          "is-content-left-aligned": true,
          onClose: _ctx.CloseModelPopup,
          onSave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.DeleteModelPopUp($event)))
        }, null, 8, ["modal-pop-up", "onClose"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      (!_ctx.showChatDetailSection)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("EventSetting.Chat.DiscussionChannels")), 1),
                _createElementVNode("i", {
                  class: "fa fa-info-circle information-icon margin-bottom-5 margin-left-5",
                  onMouseover: _cache[1] || (_cache[1] = ($event: any) => (_ctx.GetInformationString(true))),
                  onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.GetInformationString(false)))
                }, null, 32),
                _withDirectives(_createElementVNode("div", { class: "information-icon-div" }, _toDisplayString(_ctx.$t('ChatRequiredAppVersionTooltip')), 513), [
                  [_vShow, _ctx.showInfoString]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", null, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t("EventSetting.Chat.Discription")), 1)
              ]),
              _createElementVNode("div", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localChats, (localChat) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: localChat.Id,
                    class: "div-chat-item"
                  }, [
                    _createVNode(_component_ChatListing, {
                      "chat-data": localChat,
                      onEdit: _cache[3] || (_cache[3] = ($event: any) => (_ctx.EditChannelDetail($event))),
                      onDelete: _cache[4] || (_cache[4] = ($event: any) => (_ctx.DeleteChannel($event)))
                    }, null, 8, ["chat-data"])
                  ]))
                }), 128)),
                _cache[9] || (_cache[9] = _createElementVNode("hr", { class: "separator" }, null, -1)),
                _createElementVNode("div", {
                  class: "div-create-channel",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.ShowChatDetail(true)))
                }, [
                  _createElementVNode("img", { src: _ctx.addIcon }, null, 8, _hoisted_8),
                  _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t("EventSetting.Chat.CreateChannel")), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("a", {
                  class: "btn btn-size-header-main btn-secondary open-event-chat-button cursor-pointer",
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.RedirectToChat()))
                }, [
                  _createTextVNode(_toDisplayString(_ctx.$t('EventSetting.Chat.OpenEventChat')) + " ", 1),
                  _createElementVNode("img", {
                    src: _ctx.previewIcon,
                    class: "margin-left-5"
                  }, null, 8, _hoisted_11)
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.showChatDetailSection)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createVNode(_component_ChatDetail, {
              "chat-data": _ctx.selectedChatData,
              onGetUpdatedChatChannelsData: _cache[7] || (_cache[7] = ($event: any) => (_ctx.GetChannelData())),
              onBackNavigation: _cache[8] || (_cache[8] = ($event: any) => (_ctx.ShowChatDetail(false)))
            }, null, 8, ["chat-data"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}